import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import IntlContext from 'core/contexts/Intl'
import logo from '../../assets/video/100_Homes_1x.gif'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.getActive = this.getActive.bind(this)
  }

  toggleMenu() {
    this.setState(state => {
      return { menuOpen: !state.menuOpen }
    })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  getActive(path) {
    if (path === '/') return 'overview'
    const parts = path.split('/')

    return parts[1] || false
  }

  render() {
    const active = this.getActive(this.props.location.pathname)

    return (
      <IntlContext>
        {intl => {
          return (
            <header className={`header ${this.state.menuOpen ? 'is-menu-open' : ''}`}>
              <div className="header-branding">
                <h1 className="header-logo">
                  <Link to="/" className="header-logo-link" onClick={this.closeMenu}>
                    <img
                      src={logo}
                      className="header-gif"
                      alt={intl.formatMessage({ id: 'global.100_homes' })}
                    />
                  </Link>
                </h1>
              </div>

              <nav className="header-nav">
                <ul className="header-nav-list">
                  <li className={`header-nav-item ${active === 'overview' ? 'is-active' : ''}`}>
                    <Link className="header-nav-item-link" to="/" onClick={this.closeMenu}>
                      <FormattedMessage id="menu.overview" defaultMessage="Overview" />
                    </Link>
                  </li>
                  <li className={`header-nav-item ${active === 'lessons' ? 'is-active' : ''}`}>
                    <Link
                      className="header-nav-item-link"
                      to="/lessons/1/game/rich-or-poor"
                      onClick={this.closeMenu}
                    >
                      <FormattedMessage id="menu.lessons" defaultMessage="Lessons" />
                    </Link>
                  </li>
                  <li className={`header-nav-item ${active === 'the-homes' ? 'is-active' : ''}`}>
                    <Link className="header-nav-item-link" to="/the-homes" onClick={this.closeMenu}>
                      <FormattedMessage id="menu.the_homes" defaultMessage="The Homes" />
                    </Link>
                  </li>
                  <li className={`header-nav-item ${active === 'about' ? 'is-active' : ''}`}>
                    <Link className="header-nav-item-link" to="/about" onClick={this.closeMenu}>
                      <FormattedMessage id="menu.about" defaultMessage="About" />
                    </Link>
                  </li>
                </ul>
              </nav>

              <button onClick={this.toggleMenu} className="header-nav-toggle u-btn-plain">
                <div className="header-nav-toggle-bar mod-top"></div>
                <div className="header-nav-toggle-bar mod-middle"></div>
                <div className="header-nav-toggle-bar mod-bottom"></div>
              </button>
            </header>
          )
        }}
      </IntlContext>
    )
  }
}

export default Header
