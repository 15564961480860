import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import IntlContext from 'core/contexts/Intl'

import AppState from 'core/contexts/AppState'

import Paragraphed from 'components/Paragraphed'

class HouseDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }

    this.toggleContent = this._toggleContent.bind(this)
  }

  _toggleContent() {
    this.context.setState({ infoOpen: !this.context.state.infoOpen })
  }

  render() {
    const stateClass = `is-${this.context.state.infoOpen ? 'open' : 'closed'}`
    const toggleLabelID = this.context.state.infoOpen ? 'global.close' : 'global.open'

    return (
      <IntlContext>
        {intl => {
          return (
            <div className="house-details">
              <div className="house-details-inner">
                <div className="house-details-header">
                  <div className="house-details-header-title">
                    <div className="house-details-header-house">
                      <FormattedMessage id="global.home" /> {this.props.house.index + 1}
                    </div>
                    <div className="house-details-header-location">
                      {[
                        // this.props.house.tehsil_taluka,
                        this.props.house.district_name,
                        this.props.house.state_name
                      ].join(', ')}
                    </div>
                  </div>
                </div>
                <div className={`house-details-content ${stateClass}`}>
                  <img
                    className="house-details-content-img"
                    src={`/assets/maps/${this.props.house.district_name}.png`}
                    alt={`${this.props.house.district_name} location map`}
                  />
                  <Paragraphed>{this.props.house.Field_Notes_EN}</Paragraphed>
                </div>
              </div>
              <button
                className={`house-details-trigger ${stateClass}`}
                onClick={this.toggleContent}
                aria-label={intl.formatMessage({ id: toggleLabelID })}
                title={intl.formatMessage({ id: toggleLabelID })}
              >
                +
              </button>
            </div>
          )
        }}
      </IntlContext>
    )
  }
}

HouseDetails.propTypes = {
  house: PropTypes.object.isRequired
}

HouseDetails.contextType = AppState

export default HouseDetails
