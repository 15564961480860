import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const AssetListItem = props => {
  // const size = getWindowSize()
  // let height = size.width / 6

  // if (height > 200) height = 200
  // if (height < 80) height = 80

  // const ratio = props.asset.width / props.asset.height
  // const style = {
  //   height: height,
  //   width: height * ratio
  // }

  return (
    <li className={`asset-list-item mod-category-${props.asset.category.id}`}>
      <Link
        to={`/the-homes/house/${props.house.House_ID}/category/${props.asset.category.id}?id=${props.asset.id}`}
        className="asset-list-item-link"
      >
        {(() => {
          switch (props.asset.type) {
            case 'image':
            case 'pano-image':
              const media = props.asset.poster || props.asset
              return (
                <img
                  className={`asset-list-item-media mod-${props.asset.type}`}
                  src={`/assets/houses/${media.url}?nf_resize=fit&w=500`}
                />
              )
          }
        })()}
      </Link>
    </li>
  )
}

AssetListItem.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired
  }).isRequired,
  house: PropTypes.object.isRequired
}

export default AssetListItem
