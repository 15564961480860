import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Menu from 'core/components/Menu'
import Intro from 'views/Intro'
import House from 'views/House'
import Category from 'views/Category'

const TheHomes = props => {
  return (
    <div className="the-homes">
      <TransitionGroup component={null}>
        <CSSTransition key={props.location.key} classNames="fade" timeout={700}>
          <Switch location={props.location}>
            <Route exact path="/the-homes" render={props => <Intro />} />
            <Route
              exact
              path="/the-homes/house/:id"
              render={props => <House houseID={props.match.params.id} />}
            />
            <Route
              exact
              path="/the-homes/house/:id/category/:category"
              render={props => (
                <Category
                  {...props}
                  houseID={props.match.params.id}
                  categoryID={props.match.params.category}
                />
              )}
            />
            {/* <Route render={() => <div>Not Found</div>} /> */}
            <Redirect to="/the-homes" />
          </Switch>
        </CSSTransition>
      </TransitionGroup>

      <Menu location={location} homes={props.homes} />
    </div>
  )
}

export default TheHomes
