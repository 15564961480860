import React from 'react'

import houses from 'data/houses.json'
import categories from 'data/categories.json'

const Context = React.createContext()
const { Provider, Consumer } = Context

class AppStateProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'ready',
      houses,
      categories,
      category: null,
      house: null,
      infoOpen: false
    }

    this.updateState = this._updateState.bind(this)
  }

  _updateState(state) {
    return new Promise((resolve, reject) => {
      const newState = { ...this.state, ...state }

      if (this.state.house && state.house && state.house.House_ID !== this.state.house.House_ID) {
        newState.infoOpen = false
      }

      return this.setState(newState, s => {
        resolve(s)
      })
    })
  }

  render() {
    return (
      <Provider value={{ state: this.state, setState: this.updateState }}>
        {this.props.children}
      </Provider>
    )
  }
}

export { AppStateProvider, Consumer as AppStateConsumer }

export default Context
