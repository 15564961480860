import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import LessonNav from 'components/LessonNav'
import RichOrPoor from 'components/games/RichOrPoor'
// import ChangingAssumptions from 'components/games/ChangingAssumptions'
// import SnapshotsInTime from 'components/games/SnapshotsInTime'
import LessonVideo from 'components/LessonVideo'
import LessonImageSlider from 'components/LessonImageSlider'

class Lessons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewIndex: 0,
      direction: 'left'
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const viewIndex = parseInt(props.match.params.index, 10)

    if (viewIndex === prevState.viewIndex) return null

    const direction = viewIndex < prevState.viewIndex ? 'right' : 'left'

    return { viewIndex, direction }
  }

  render() {
    const animClasses = this.state.direction ? `slide-${this.state.direction}` : ''
    const animTime = this.state.direction ? 700 : 0
    return (
      <div className="lessons">
        <TransitionGroup component={null} childFactory={childFactoryCreator(animClasses)}>
          <CSSTransition key={this.props.location.key} classNames={animClasses} timeout={animTime}>
            <Switch location={this.props.location}>
              <Route
                exact
                path="/lessons/:index/game/:game"
                render={props => {
                  switch (props.match.params.game) {
                    case 'rich-or-poor':
                      return <RichOrPoor />
                    // case 'changing-assumptions':
                    //   return <ChangingAssumptions />
                    // case 'snapshots-in-time':
                    //   return <SnapshotsInTime />
                  }
                }}
              />
              <Route
                exact
                path="/lessons/:index/video/:video"
                render={props => <LessonVideo {...props} videoID={props.match.params.video} />}
              />
              <Route
                exact
                path="/lessons/:index/slider/:slider"
                render={props => (
                  <LessonImageSlider {...props} sliderID={props.match.params.slider} />
                )}
              />
              <Redirect to="/lessons/1/game/rich-or-poor" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>

        <LessonNav {...this.props} />
      </div>
    )
  }
}

const childFactoryCreator = classNames => child =>
  React.cloneElement(child, {
    classNames
  })

export default Lessons
