export default {
  // 'graph-and-line': {
  //   videoSrc: require('./videos/graph-and-line.mp4').default,
  //   poster: require('./images/graph-and-line.jpg').default,
  //   titleId: 'lessons.graph_and_line_title',
  //   descriptionId: 'lessons.graph_and_line_description',
  //   nextLink: '/lessons/3/video/changing-assumptions'
  // },
  // 'changing-assumptions': {
  //   videoSrc: require('./videos/changing-assumptions.mp4').default,
  //   poster: require('./images/changing-assumptions.jpg').default,
  //   titleId: 'lessons.changing_assumptions_title',
  //   descriptionId: 'lessons.changing_assumptions_description',
  //   nextLink: '/lessons/4/slider/rajasthan'
  // },

  'graph-and-line': {
    videoSrc: require('./videos/graph-and-line.mp4').default,
    poster: require('./images/graph-and-line.jpg').default,
    titleId: 'lessons.graph_and_line_title',
    descriptionId: 'lessons.graph_and_line_description',
    nextLink: '/lessons/3/video/graph-problems'
  },
  'graph-problems': {
    videoSrc: require('./videos/graph-problems.mp4').default,
    poster: require('./images/graph-problems.jpg').default,
    titleId: 'lessons.graph_problems_title',
    descriptionId: 'lessons.graph_problems_description',
    nextLink: '/lessons/4/slider/rajasthan'
  },
  'line-problems': {
    videoSrc: require('./videos/line-problems.mp4').default,
    poster: require('./images/line-problems.jpg').default,
    titleId: 'lessons.line_problems_title',
    descriptionId: 'lessons.line_problems_description',
    nextLink: '/the-homes',
    nextLinkContentId: 'lessons.continue_to_homes'
  }
}
