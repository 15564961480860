import React from 'react'

const InfoWrapper = props => {
  const classNames = `info-wrapper ${props.className}`
  return (
    <div className={classNames.trim()} style={props.style}>
      {props.children}
    </div>
  )
}

InfoWrapper.defaultProps = {
  className: ''
}

export default InfoWrapper
