import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { AppStateConsumer } from 'core/contexts/AppState'

import { currency, dollarsToRupees } from 'core/helpers/language-helpers'
import { getHouseByID } from 'core/helpers/house-helpers'
import { getAssetsByCategory } from 'core/helpers/asset-helpers'

import AssetSliderPano from 'components/AssetSliderPano'

const houses = [
  ['4', '52'],
  ['25', '10'],
  ['11', '39'],
  // ['31', '47'], // Not panos, and 47 doesn't load.
  ['21', '33'],
  ['59', '36']
]

class RichOrPoor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      active: true,
      redirect: false,
      status: 'asking',
      answer: null,
      pairs: []
    }

    this.dismissTitleCard = this._dismissTitleCard.bind(this)
    this.shuffleArray = this._shuffleArray.bind(this)
    this.answer = this._answer.bind(this)
    this.getData = this._getData.bind(this)
    this.advance = this._advance.bind(this)
  }

  _dismissTitleCard() {
    const shuffled = this.shuffleArray(houses)

    this.setState({ pairs: shuffled })
  }

  _shuffleArray(array) {
    const a = array.slice(0, 3)

    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }

    return a
  }

  _getData(houseArray, ids) {
    const houses = ids.map(id => {
      return getHouseByID(id, houseArray)
    })

    const output = houses.map((house, index) => {
      const dpd = house.dollar_per_day_per_capita

      const featuredImg = getAssetsByCategory(house.assets, 'rpgame')
      const otherHouse = houses[index === 0 ? 1 : 0]

      return {
        house,
        img: featuredImg[0],
        dpd: currency(dpd),
        rpd: currency(dollarsToRupees(dpd), { currency: 'INR' }),
        correct: dpd > otherHouse.dollar_per_day_per_capita
      }
    })

    return output
  }

  _answer(data) {
    if (this.state.answer) return
    this.setState({ answer: data.house.House_ID })
  }

  _advance() {
    if (!this.state.pairs[this.state.index + 1]) return

    this.setState(() => {
      return {
        index: this.state.index + 1,
        answer: null
      }
    })
  }

  render() {
    if (!this.state.pairs.length) {
      return (
        <article className="rich-or-poor-titlecard">
          <div className="rich-or-poor-titlecard-copy">
            <h1 className="rich-or-poor-titlecard-title">
              <FormattedMessage id="rich_or_poor.which_home_is_wealthier" />
            </h1>

            <p className="rich-or-poor-titlecard-description">
              <FormattedMessage id="rich_or_poor.description" />
            </p>

            <div className="rich-or-poor-titlecard-action">
              <button className="u-btn mod-hot mod-small" onClick={this.dismissTitleCard}>
                <FormattedMessage id="global.begin" />
              </button>
            </div>
          </div>
        </article>
      )
    }

    return (
      <AppStateConsumer>
        {context => {
          const data = this.getData(context.state.houses, this.state.pairs[this.state.index])

          return (
            <div className={`rich-or-poor ${this.state.answer ? 'is-answered' : 'is-asking'}`}>
              <div className="rich-or-poor-main">
                {data.map(d => {
                  let buttonClasses = [
                    'rich-or-poor-select',
                    d.correct ? 'mod-correct' : 'mod-incorrect'
                  ]

                  if (this.state.answer) {
                    buttonClasses.push(
                      this.state.answer === d.house.House_ID ? 'is-answer' : 'is-not-answer'
                    )
                  }

                  return (
                    <div key={d.house.House_ID} className="rich-or-poor-inner">
                      <AssetSliderPano
                        media={d.img}
                        preloaded={true}
                        active={this.state.active}
                        drift={!this.state.answer}
                      />

                      <div className="rich-or-poor-dpd">
                        <div className="rich-or-poor-dpd-text">
                          {d.dpd} / {d.rpd}
                          <div className="rich-or-poor-dpd-label">
                            <FormattedMessage id="global.per_day" />
                          </div>
                        </div>
                      </div>

                      <button
                        id={`select_${d.house.House_ID}`}
                        className={buttonClasses.join(' ')}
                        onClick={this.answer.bind(null, d)}
                      >
                        <span className="rich-or-poor-select-icon">
                          {d.correct ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
                              <path
                                fill="#fff"
                                d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                              />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                fill="#fff"
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                              />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div>
                  )
                })}
              </div>

              <div className="rich-or-poor-body">
                <div className="rich-or-poor-copy">
                  <div className="rich-or-poor-progress">
                    {this.state.index + 1}/{this.state.pairs.length}{' '}
                  </div>
                  <h3 className="rich-or-poor-question">
                    <FormattedMessage id="rich_or_poor.which_home_is_wealthier" />
                  </h3>
                </div>
                {this.state.answer && (
                  <div className="rich-or-poor-action">
                    {this.state.pairs[this.state.index + 1] ? (
                      <button onClick={this.advance} className="u-btn mod-hot mod-small">
                        <FormattedMessage id="global.continue" />
                      </button>
                    ) : (
                      <Link
                        className="u-btn mod-hot mod-small"
                        to="/lessons/2/video/graph-and-line"
                      >
                        <FormattedMessage id="global.continue" />
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }}
      </AppStateConsumer>
    )
  }
}

export default RichOrPoor
