import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { getHousesWithFeatured } from 'core/helpers/house-helpers'
import { getDefaultCategory } from 'core/helpers/category-helpers'
import AppState from 'core/contexts/AppState'
import InfoWrapper from 'components/InfoWrapper'
import Info from 'components/Info'
import IntroBackground from 'components/IntroBackground'

class Intro extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      queue: null,
      current: 0,
      loadedIndex: 1
    }

    this.timer = null
    this.advanceCurrent = this._advanceCurrent.bind(this)
    this.stopInterval = this._stopInterval.bind(this)
  }

  componentDidMount() {
    const queue = this._shuffleArray(getHousesWithFeatured(this.context.state.houses))

    this.setState({ queue }, () => {
      this.context.setState({ house: queue[0], category: null })
      this.timer = setInterval(() => {
        this.advanceCurrent()
      }, 10000)
    })
  }

  componentWillUnmount() {
    this.stopInterval()
  }

  _shuffleArray(array) {
    const a = array.slice()

    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }

    return a
  }

  _advanceCurrent() {
    const nextIndex = this.state.current + 1
    const current = this.state.queue[nextIndex] ? nextIndex : 0
    const loadedIndex = current + 1 > this.state.loadedIndex ? current + 1 : this.state.loadedIndex
    this.context.setState({ house: this.state.queue[current] })
    this.setState({ current, loadedIndex })
  }

  _stopInterval() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    const defaultCategory = getDefaultCategory(this.context.state.categories) || {}
    return (
      <article className="intro">
        {this.state.queue && <IntroBackground {...this.state} />}
        <div className="u-scrim" />
        {this.state.queue && (
          <Fragment>
            <InfoWrapper>
              <Info house={this.state.queue[this.state.current]} />
            </InfoWrapper>
            <Link
              to={`/the-homes/house/${this.state.queue[this.state.current].House_ID}/category/${
                defaultCategory.id
              }`}
              className="intro-explore-btn u-btn mod-hot"
              onClick={this.stopInterval}
            >
              <FormattedMessage id="global.explore_house" />
            </Link>
          </Fragment>
        )}
      </article>
    )
  }
}

Intro.propTypes = {
  homes: PropTypes.array
}

Intro.contextType = AppState

export default Intro
