import React from 'react'
import PropTypes from 'prop-types'

import IntlContext from 'core/contexts/Intl'

const CategorySelect = props => {
  return (
    <IntlContext>
      {intl => {
        return (
          <div className="category-select">
            <ul className="category-select-list">
              <select
                className="category-select-dropdown"
                id="category"
                value={props.selected || ''}
                onChange={e => {
                  props.onSelect(e.target.value)
                }}
              >
                {props.categories.map((cat, index) => (
                  <option key={index} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </ul>
          </div>
        )
      }}
    </IntlContext>
  )
}

CategorySelect.propTypes = {
  categories: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}

export default CategorySelect
