import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import BurstMode from '@material-ui/icons/BurstMode'
import ViewComfy from '@material-ui/icons/ViewComfy'
import { getDefaultCategory } from 'core/helpers/category-helpers'
import IntlContext from 'core/contexts/Intl'

const LayoutToggle = props => {
  const houseRoute = `/the-homes/house/${props.house.House_ID}`
  const defaultCategory = getDefaultCategory(props.categories) || {}

  return (
    <IntlContext>
      {intl => (
        <div className="layout-toggle">
          <Link
            className={`layout-toggle-link mod-grid ${props.active === 'grid' ? 'is-active' : ''}`}
            to={`${houseRoute}`}
            title={intl.formatMessage({ id: 'global.masonry_view' })}
            disabled={props.active === 'grid' ? 'is-active' : ''}
          >
            <ViewComfy style={{ fontSize: 'inherit' }} />
          </Link>
          <Link
            className={`layout-toggle-link mod-slideshow ${
              props.active === 'slideshow' ? 'is-active' : ''
            }`}
            to={`/the-homes/house/${props.house.House_ID}/category/${defaultCategory.id}`}
            title={intl.formatMessage({ id: 'global.slider_view' })}
            disabled={props.active === 'slideshow' ? 'is-active' : ''}
          >
            <BurstMode style={{ fontSize: 'inherit' }} />
          </Link>
        </div>
      )}
    </IntlContext>
  )
}

LayoutToggle.defaultProps = {}

LayoutToggle.propTypes = {
  active: PropTypes.oneOf(['grid', 'slideshow']).isRequired,
  house: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
}

export default LayoutToggle
