export default {
  rajasthan: {
    leftImage: require('./images/rajasthan_before.jpg').default,
    rightImage: require('./images/rajasthan_after.jpg').default,
    descriptionId: 'lessons.rajasthan_description',
    nextLink: '/lessons/5/slider/assam'
  },
  assam: {
    leftImage: require('./images/assam_before.jpg').default,
    rightImage: require('./images/assam_after.jpg').default,
    descriptionId: 'lessons.assam_description',
    nextLink: '/lessons/6/video/line-problems'
  }
}
