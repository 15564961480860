import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import AppState, { AppStateConsumer } from 'core/contexts/AppState'
import { updateMeta } from 'core/helpers/general-helpers'
import { getHouseByID, getPreviousHouse, getNextHouse } from 'core/helpers/house-helpers'
import { getAssetsByCategory } from 'core/helpers/asset-helpers'

import InfoWrapper from 'components/InfoWrapper'
import Info from 'components/Info'
import AssetList from 'components/AssetList'
import LayoutToggle from 'components/LayoutToggle'
import HouseDetails from 'components/HouseDetails'

class House extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      house: null,
      category: null
    }
  }

  componentDidMount() {
    const house = getHouseByID(this.props.houseID, this.context.state.houses)

    updateMeta({ title: `House ${house.House_ID}`, description: 'This is an individual house.' })

    this.context.setState({ house, category: null })
    this.setState({
      house,
      previous: getPreviousHouse(this.props.houseID, this.context.state.houses),
      next: getNextHouse(this.props.houseID, this.context.state.houses)
    })
  }

  render() {
    let assets
    assets = this.state.house
      ? this.context.state.categories.reduce((acc, cat) => {
          const catAssets = getAssetsByCategory(this.state.house.assets, cat.id)
            .map(a => ({
              ...a,
              category: cat
            }))
            .filter(asset => !acc.find(a => a.id === asset.id) && asset.type !== 'pano-image')

          return [...acc, ...catAssets]
        }, [])
      : []

    return (
      <article className="house">
        {this.state.house && assets.length > 0 && (
          <div>
            <div className="asset-list-wrapper">
              <AssetList house={this.state.house} assets={assets} />
            </div>
            <div className="u-scrim" />
            <InfoWrapper>
              <Info
                house={this.state.house}
                navigation={{
                  previous: `/the-homes/house/${this.state.previous.House_ID}`,
                  next: `/the-homes/house/${this.state.next.House_ID}`
                }}
              />
            </InfoWrapper>

            <InfoWrapper style={{ bottom: '4rem', top: 'auto' }}>
              <HouseDetails house={this.state.house} />
            </InfoWrapper>

            <LayoutToggle
              house={this.state.house}
              categories={this.context.state.categories}
              active="grid"
            />
          </div>
        )}
      </article>
    )
  }
}

House.contextType = AppState

House.propTypes = {
  houseID: PropTypes.string.isRequired
}

export default House
