import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

// import { setTimeout } from 'timers'

import lessonData from './lesson-video-data'

/**
 * Note:
 *
 * Posters are placed in src/components/LessonVideo/images/
 * Videos are placed in src/assets/video/
 *
 * Filenames must correspond to URL endpoints, eg.
 * graph-and-line
 * counterintuitive-data
 * walkthrough
 *
 * If it is necessary to change any of these, please edit
 * src/components/LessonNav/LessonNav.js/links[]
 *
 * The currently existing files are just overview.mp4 and overview.jpg renamed,
 * please delete these and replace them with the real content.
 */
class LessonVideo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'init'
    }

    this.playerRef = React.createRef()
    this.beginVideo = this._beginVideo.bind(this)
    this.pauseVideo = this._pauseVideo.bind(this)
  }

  _beginVideo() {
    this.playerRef.current.play()
    this.setState({ status: 'played' })
  }

  _pauseVideo() {
    this.playerRef.current.pause()
  }

  render() {
    const { videoID } = this.props
    const data = lessonData[videoID]
    if (!data) {
      return null
    }

    const poster = data.poster
    const video = data.videoSrc

    return (
      <div className={`lesson-video is-${this.state.status}`}>
        <div className="lesson-video-media">
          <video
            className="lesson-video-media-player"
            controls={this.state.status !== 'init'}
            poster={poster}
            ref={this.playerRef}
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>

        <article className="lesson-video-titlecard">
          <div className="lesson-video-titlecard-copy">
            <h1 className="lesson-video-titlecard-title">
              <FormattedMessage id={data.titleId} />
            </h1>

            <p className="lesson-video-titlecard-description">
              <FormattedMessage id={data.descriptionId} />
            </p>

            <div className="lesson-video-titlecard-action">
              {this.state.status === 'init' ? (
                <button
                  className="lesson-video-titlecard-action-button u-btn mod-hot mod-small"
                  onClick={this.beginVideo}
                >
                  <FormattedMessage id="global.begin" />
                </button>
              ) : (
                <Link
                  to={data.nextLink}
                  className="lesson-video-titlecard-action-button u-btn mod-hot mod-small"
                  onClick={this.pauseVideo}
                >
                  <FormattedMessage id={data.nextLinkContentId || 'global.continue'} />
                </Link>
              )}
            </div>
          </div>
        </article>
      </div>
    )
  }
}

LessonVideo.propTypes = {
  videoID: PropTypes.string.isRequired
}

export default LessonVideo
