import React from 'react'
import { Link } from 'react-router-dom'
import AppState, { AppStateConsumer } from 'core/contexts/AppState'
import { getDefaultCategory } from 'core/helpers/category-helpers'

import IntlContext from 'core/contexts/Intl'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = { data: null }

    this.sortHouses = this._sortHouses.bind(this)
    this.getGroupIndex = this._getGroupIndex.bind(this)
  }

  componentDidMount() {
    this.setState({ data: this.sortHouses(this.context.state.houses) })
  }

  _getGroupIndex(home) {
    return Math.floor(home.IHDS_2012_Annual_Terms_Graphed / 4000)
  }

  _sortHouses(houses) {
    const lastGroupIndex = this.getGroupIndex(houses[houses.length - 1])
    const output = []

    for (let i = 0; i <= lastGroupIndex; i++) {
      output.push(houses.filter(house => this.getGroupIndex(house) === i))
    }

    return output
  }

  render() {
    if (!this.state.data) return null
    // if (this.props.location.pathname.substring(0, 10) !== '/the-homes') return null

    return (
      <IntlContext>
        {intl => (
          <AppStateConsumer>
            {context => {
              const category =
                context.state.category || getDefaultCategory(context.state.categories)
              return (
                <div
                  className={`menu is-${
                    this.props.location.pathname === '/the-homes' ? 'full' : 'small'
                  }`}
                >
                  {this.state.data.map((column, index) => (
                    <div key={index} className={`menu-column mod-${index}`}>
                      {column
                        .map((item, index) => {
                          const isActive = (context.state.house || {}).House_ID === item.House_ID

                          return (
                            <Link
                              to={`/the-homes/house/${item.House_ID}/category/${category.id}`}
                              key={item.House_ID}
                              className={`menu-item ${isActive ? 'is-active' : ''}`}
                              title={`${intl.formatMessage({
                                id: 'global.home',
                                defaultMessage: 'Home'
                              })} ${item.House_ID}`}
                            />
                          )
                        })
                        .reverse()}
                    </div>
                  ))}
                </div>
              )
            }}
          </AppStateConsumer>
        )}
      </IntlContext>
    )
  }
}

Menu.contextType = AppState

export default Menu
