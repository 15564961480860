import React from 'react'
import { Link } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'

import overviewVideo from 'assets/video/overview-vid.mp4'
import overviewPoster from './images/overview-poster.jpg'
import OverviewCarousel from 'components/OverviewCarousel'

class Overview extends React.Component {
  constructor(props) {
    super(props)
    this.playerRef = React.createRef()

    this.state = {
      status: 'init',
    }

    this.timeout = null

    this.begin = this.begin.bind(this)
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  begin() {
    let that = this
    this.setState({ status: 'beginning' }, () => {
      that.timeout = setTimeout(() => {
        this.setState({ status: 'begun' })
        if (this.playerRef.current) {
          this.playerRef.current.play()
        }
      }, 300)
    })
  }

  render() {
    return (
      <article className="overview">
        <div className={`overview-inner mod-${this.state.status}`}>
          <div className="overview-background">
            <video
              className="overview-video"
              controls
              playsInline
              poster={overviewPoster}
              ref={this.playerRef}
            >
              <source src={overviewVideo} type="video/mp4" />
            </video>
            <Link
              className="overview-lessons-link u-btn mod-hot mod-small"
              to="/lessons/1/game/rich-or-poor"
            >
              <FormattedMessage id="global.lessons" />
            </Link>
          </div>
          <div className="overview-foreground">
            {window.innerWidth > 599 && <OverviewCarousel />}

            <div className="overview-foreground-text">
              <p>
                <span className="overview-foreground-text-highlight">
                  <FormattedMessage id="global.one_hundred_homes" />
                </span>{' '}
                <FormattedMessage id="global.home_description" />
              </p>
              <p style={{ textAlign: 'center' }}>
                <button className="u-btn mod-hot mod-small" onClick={this.begin}>
                  <FormattedMessage id="global.begin" />
                </button>
              </p>
            </div>
          </div>
        </div>
      </article>
    )
  }
}

export default Overview
