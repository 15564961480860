export const getCategoryByID = (id, categories) => {
  return categories.find(c => c.id === id) || false
}

export const getDefaultCategory = categories => {
  return categories.find(c => c.default) || false
}

export const getHouseCategories = (categories, house, current = null) => {
  if (!categories) return null
  if (!house) return null
  const assetCats = house.assets.reduce((acc, val) => {
    const arr = val.categories || []
    return [...acc, ...arr]
  }, [])

  return categories.filter(cat => assetCats.indexOf(cat.id) !== -1 || cat.id === current)
}
