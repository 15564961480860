import React from 'react'
import { injectIntl } from 'react-intl'
const { Provider, Consumer } = React.createContext()

class IntlProviderClass extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Provider value={this.props.intl}>{this.props.children}</Provider>
  }
}

const IntlProvider = injectIntl(IntlProviderClass)

export { IntlProvider }

export default Consumer
