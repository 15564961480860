export const updateMeta = (meta = {}) => {
  const options = { title: process.env.PROJECT_TITLE, ...meta }
  document.title = options.title
  document.querySelector('meta[name="description"]').setAttribute('content', options.description)
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute('content', options.description)
}

export const getWindowSize = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth,
    height = w.innerHeight || e.clientHeight || g.clientHeight

  return { height, width }
}
