import React from 'react'

import { Link } from 'react-router-dom'

const LessonNav = props => {
  return (
    <nav className="lesson-nav">
      {links.map((link, i) => {
        const url = `/lessons/${i + 1}${link.url}`
        const active = props.location.pathname === url
        return (
          <Link
            key={i}
            className={`lesson-nav-link ${active ? 'is-active' : ''}`}
            to={url}
            onClick={e => {
              if (active) e.preventDefault()
            }}
          ></Link>
        )
      })}
    </nav>
  )
}

const links = [
  { url: '/game/rich-or-poor' },
  { url: '/video/graph-and-line' },
  { url: '/video/graph-problems' },
  { url: '/slider/rajasthan' },
  { url: '/slider/assam' },
  { url: '/video/line-problems' }
  // { url: '/game/snapshots-in-time' },
  // { url: '/video/walkthrough' }
]

export default LessonNav
