export const getHouseByID = (id, houses) => {
  return houses.find(h => h.House_ID === id)
}

export const getHousesWithFeatured = houses => {
  return houses.filter(
    house => !!house.assets.find(asset => asset.categories.indexOf('featured') !== -1)
  )
}

export const getPreviousHouse = (id, houses) => {
  const index = houses.findIndex(h => h.House_ID === id)
  const previousHouse = houses[index - 1]
  return previousHouse || houses[houses.length - 1]
}

export const getNextHouse = (id, houses) => {
  const index = houses.findIndex(h => h.House_ID === id)
  const nextHouse = houses[index + 1]
  return nextHouse || houses[0]
}
