import React from 'react'
import ReactCompareImage from 'react-compare-image'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import sliderData from './image-slider-data'

const LessonImageSlider = props => {
  const data = sliderData[props.sliderID]

  return (
    <div className="image-slider">
      <div className="image-slider-container">
        <ReactCompareImage
          leftImage={data.leftImage}
          rightImage={data.rightImage}
          style={{ height: '100%' }}
        />
      </div>

      <div className="image-slider-content">
        <p className="image-slider-description">
          <FormattedMessage id={data.descriptionId} />
        </p>

        <Link to={data.nextLink} className="image-slider-action u-btn mod-hot mod-small">
          <FormattedMessage id={data.nextLinkContentId || 'global.continue'} />
        </Link>
      </div>
    </div>
  )
}

export default LessonImageSlider
