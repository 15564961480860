import React from 'react'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import AssetSliderImage from 'components/AssetSliderImage'
import AssetSliderPano from 'components/AssetSliderPano'

class AssetSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: null,
      offset: 0,
      preload: [],
      status: 'loading'
    }

    this.containerRef = React.createRef()
    this.slideRefs = []
    this.slideRef = slide => {
      if (slide) {
        this.slideRefs = [...this.slideRefs, slide]
      }
    }

    this.init = this._init.bind(this)
    this.resize = this._resize.bind(this)
    this.orient = this._orient.bind(this)
    this.setActiveSlide = this._setActiveSlide.bind(this)
    this.getPreloaded = this._getPreloaded.bind(this)
    this.getSlideClasses = this._getSlideClasses.bind(this)
    this.getPlaceholderSrc = this._getPlaceholderSrc.bind(this)
  }

  componentDidMount() {
    this.init()
    window.addEventListener('resize', this.resize, false)
    // window.addEventListener('load', this.init, false)
  }

  componentWillUnmount() {
    // window.removeEventListener('load', this.init, false)
    window.removeEventListener('resize', this.resize, false)
  }

  _init() {
    this.setActiveSlide(this.props.assets[this.props.initial] ? this.props.initial : 0, () => {
      setTimeout(() => {
        this.setState({ status: 'ready' })
      })
    })
  }

  _resize() {
    this.orient()
  }

  _orient(cb = () => {}) {
    setTimeout(() => {
      const totalWidth = this.containerRef.current.offsetWidth
      const active = this.slideRefs[this.state.active]

      const offset = totalWidth / 2 - (active.offsetLeft + active.offsetWidth / 2)
      this.setState({ offset }, cb)
    })
  }

  _setActiveSlide(active, cb = () => {}) {
    if (!this.props.assets[active]) return

    const preload = this.getPreloaded([active - 1, active, active + 1])

    this.setState({ active, preload }, () => {
      this.orient(cb)
    })
  }

  _getPreloaded(indexes = []) {
    const current = this.state.preload
    const preload = indexes.filter(i => this.props.assets[i] && current.indexOf(i) === -1)

    return [...current, ...preload]
  }

  _getSlideClasses(index, defaults = []) {
    let output = defaults

    if (index === this.state.active) {
      output = [...defaults, 'is-active']
    }

    return output.join(' ')
  }

  _getPlaceholderSrc(width, height) {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`
  }

  render() {
    if (typeof this.state.active !== 'number') return null
    if (this.props.assets.length <= 0) return null

    const prevAsset = this.props.assets[this.state.active - 1]
    const nextAsset = this.props.assets[this.state.active + 1]

    const catIndex = this.props.categories.findIndex(
      cat => cat.id === this.props.currentCategory.id
    )

    const prevCat = this.props.categories[catIndex - 1]
    const nextCat = this.props.categories[catIndex + 1]

    return (
      <div className={`asset-slider is-${this.state.status}`}>
        <div
          className={`asset-slider-inner`}
          style={{ transform: `translateX(${this.state.offset}px)` }}
          ref={this.containerRef}
        >
          {this.props.assets.map((asset, index) => {
            const media = asset.poster || asset
            return (
              <div
                key={index}
                className={this.getSlideClasses(index, ['asset-slider-slide'])}
                ref={this.slideRef}
              >
                <div className="asset-slider-slide-inner">
                  <img
                    className="asset-slider-slide-placeholder"
                    src={this.getPlaceholderSrc(media.width, media.height)}
                  />
                  {(() => {
                    switch (media.type) {
                      case 'image':
                        return (
                          <AssetSliderImage
                            preloaded={this.state.preload.indexOf(index) !== -1}
                            media={media}
                          />
                        )
                      case 'pano-image':
                        return (
                          <AssetSliderPano
                            active={index === this.state.active}
                            preloaded={this.state.preload.indexOf(index) !== -1}
                            media={media}
                          />
                        )
                    }
                  })()}
                </div>
              </div>
            )
          })}
        </div>

        {!prevAsset && prevCat ? (
          <Link
            to={`/the-homes/house/${this.props.house.House_ID}/category/${prevCat.id}?position=last`}
            className="asset-slider-nav u-btn-plain mod-back"
          >
            <MdChevronLeft size={60} />
            <span className="asset-slider-nav-text mod-back">{prevCat.name}</span>
          </Link>
        ) : (
          <button
            className="asset-slider-nav u-btn-plain mod-back"
            disabled={!prevAsset}
            onClick={() => {
              this.setActiveSlide(this.state.active - 1)
            }}
          >
            <span className="u-screen-reader-text">Back</span>
            <MdChevronLeft size={60} />
          </button>
        )}

        {!nextAsset && nextCat ? (
          <Link
            to={`/the-homes/house/${this.props.house.House_ID}/category/${nextCat.id}`}
            className="asset-slider-nav u-btn-plain mod-next"
          >
            <span className="asset-slider-nav-text mod-next">{nextCat.name}</span>
            <MdChevronRight size={60} />
          </Link>
        ) : (
          <button
            className="asset-slider-nav u-btn-plain mod-next"
            disabled={!nextAsset}
            onClick={() => {
              this.setActiveSlide(this.state.active + 1)
            }}
          >
            <span className="u-screen-reader-text">Next</span>
            <MdChevronRight size={60} />
          </button>
        )}
      </div>
    )
  }
}

AssetSlider.propTypes = {
  house: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  initial: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  currentCategory: PropTypes.object.isRequired
}

export default AssetSlider
