import React from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-component'
import AssetListItem from './AssetListItem'

import { getWindowSize } from 'core/helpers/general-helpers'

const masonryOptions = {
  itemSelector: '.asset-list-item',
  columnWidth: '.asset-list-item',
  gutter: 10,
  transitionDuration: 0
}

const imagesLoadedOptions = {}

const AssetList = props => {
  if (!props.assets.length) return null

  return (
    <section className="asset-list">
      {props.category && (
        <header className="asset-list-header">
          <h2 className="asset-list-title">{props.category.name}</h2>
        </header>
      )}
      <Masonry
        className="asset-list-list"
        elementType="ul"
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        imagesLoadedOptions={imagesLoadedOptions}
      >
        {props.assets.map((asset, i) => {
          return <AssetListItem key={i} {...props} asset={asset} index={i} />
        })}
      </Masonry>
    </section>
  )
}

AssetList.propTypes = {
  assets: PropTypes.array.isRequired,
  house: PropTypes.object.isRequired,
  category: PropTypes.object
}

export default AssetList
