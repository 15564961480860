import React from 'react'
import PropTypes from 'prop-types'
import { PanoViewer } from '@egjs/view360'

class AssetSliderPano extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      status: null
    }

    this.panoViewer = null

    this.playerRef = React.createRef()

    this.drift = null
    this.driftDelay = null

    this.load = this._load.bind(this)
    this.becameActive = this._becameActive.bind(this)
    this.becameInactive = this._becameInactive.bind(this)
    this.startDrift = this._startDrift.bind(this)
    this.endDrift = this._endDrift.bind(this)
    this.animate = this._animate.bind(this)
  }

  componentDidMount() {
    if (this.props.active) {
      this.becameActive()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this[this.props.active ? 'becameActive' : 'becameInactive']()
    }

    if (prevProps.drift !== this.props.drift) {
      this[this.props.drift ? 'startDrift' : 'endDrift']()
    }
  }

  _load(options = {}) {
    const _this = this
    const opts = { driftDelay: 1000, ...options }
    this.setState({ status: 'loaded' })
    this.panoViewer = new PanoViewer(this.playerRef.current, {
      image: `/assets/houses/${this.props.media.url}`,
      fov: 95
    })

    this.panoViewer.on('ready', () => {
      this.driftDelay = setTimeout(() => {
        if (this.drift === 'cancelled' || !this.props.drift) return
        _this.startDrift()
      }, opts.driftDelay)
    })
  }

  _becameActive() {
    if (this.state.status !== 'loaded') {
      setTimeout(() => {
        this.load({ driftDelay: 2000 })
      })
    }
  }

  _becameInactive() {
    if (this.panoViewer) {
      this.setState({ status: 'destroying' }, () => {
        this.endDrift()
        setTimeout(() => {
          if (this.state.status !== 'destroying') return
          this.panoViewer.destroy()
          this.drift = null
          this.setState({ status: null })
        }, 500)
      })
    }
  }

  _startDrift(delay = 0) {
    this.drift = requestAnimationFrame(this.startDrift)
    this.animate()
  }

  _animate() {
    this.panoViewer.lookAt({ yaw: this.panoViewer.getYaw() - 0.05 })
  }

  _endDrift() {
    clearTimeout(this.driftDelay)
    cancelAnimationFrame(this.drift)
    this.drift = 'cancelled'
  }

  render() {
    if (!this.props.preloaded) return null

    const poster = this.props.media.poster
      ? this.props.media.poster.url
      : `${this.props.media.url}?nf_resize=fit&h=200`

    return (
      <div className="asset-slider-pano">
        <img className="asset-slider-pano-poster" src={`/assets/houses/${poster}`} alt="" />
        <div
          ref={this.playerRef}
          id={`asset-slider-pano-player-${this.props.media.id}`}
          className="asset-slider-pano-player"
          onPointerDown={this.endDrift}
        />
        {/*!this.state.status && (
          <button
            className="asset-slider-pano-load u-btn"
            onClick={() => {
              this.load({ driftDelay: 5000 })
            }}
          >
            Load Pano
          </button>
          ) */}
      </div>
    )
  }
}

AssetSliderPano.defaultProps = {
  drift: true
}

AssetSliderPano.propTypes = {
  media: PropTypes.object.isRequired,
  preloaded: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  drift: PropTypes.bool
}

export default AssetSliderPano
