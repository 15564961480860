import React, { useState, useEffect } from 'react'

import useInterval from './hooks/use-interval'

import Img1 from './images/landing-page-1.jpg'
import Img2 from './images/landing-page-2.jpg'
import Img3 from './images/landing-page-3.jpg'
import Img4 from './images/landing-page-4.jpg'

const images = [Img1, Img2, Img3, Img4]

const OverviewCarousel = props => {
  const [active, setActive] = useState(0)

  useInterval(() => {
    const next = active + 1
    setActive(next >= images.length ? 0 : next)
  }, 4000)

  return (
    <div className="overview-carousel">
      {images.map((img, i) => {
        return (
          <div
            key={i}
            className={`overview-carousel-item ${i === active ? 'is-active' : ''}`}
            style={{ backgroundImage: `url(${img})` }}
          />
        )
      })}
      <div className="overview-carousel-scrim"></div>
    </div>
  )
}

export default OverviewCarousel
