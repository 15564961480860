export default {
  global: {
    '100_homes': '100 Homes',
    begin: 'Begin',
    changing_assumptions: 'Changing Assumptions',
    close: 'Close',
    continue: 'Continue',
    daily_ppp: 'Daily PPP',
    explore_house: 'Explore this house',
    home: 'Home',
    home_description:
      'is a visual survey of India. Examine the data and the images to discover different ways we can understand inequality.',
    house: 'House',
    less_money: 'Less money',
    loading: 'Loading',
    lessons: 'Lessons',
    masonry_view: 'Masonry View',
    more_money: 'More money',
    next: 'Next',
    one_hundred_homes: 'One Hundred Homes',
    open: 'Open',
    overview: 'Overview',
    per_day: 'Per day',
    previous: 'Previous',
    rich_or_poor: 'Which home is wealthier?',
    select: 'Select',
    skip: 'Skip',
    slider_view: 'Slider View',
    snapshots_in_time: 'Snapshots in Time',
    the_homes: 'The Homes',
    'gates-logo': 'The Bill and Melinda Gates Foundation'
  },
  category: {
    no_assets: 'No {category}'
  },
  menu: {
    about: '4. About',
    lessons: '2. Lessons',
    overview: '1. Overview',
    the_homes: '3. The Homes'
  },
  lessons: {
    assam_description:
      'This village in Assam also changed over time. A trader had approached villagers with a proposition: “If you convert your paddy fields to fish ponds, I can supply you with fingerlings (baby fish) and will buy back the grown fish” [to sell to markets]. The shift from paddy in 2011 (dark green) to fish pond in 2018 (light green) is clear. It is indeed the case that many farmers received much higher incomes from this shift.',
    continue_to_homes: 'Continue to The Homes',
    graph_and_line_title: 'The Graph And The Line',
    graph_and_line_description: 'Lets break down the distribution graph and poverty line.',
    graph_problems_title: 'Graph Problems',
    graph_problems_description:
      'There are some issues with the numbers that go into the distribution graph.',
    line_problems_title: 'Line Problems',
    line_problems_description: 'There are also some issues with the poverty line.',
    rajasthan_description:
      'A limitation of surveys is that they only show a snapshot in time, whereas people live their life as a movie. This village in Rajasthan appears quite poor in 2015, but by the time the survey arrived in 2018, the government had subsidised the construction of new houses. The village also built a good road – at the top of the picture - making it cheaper for farmers to market higher value crops.'
  },
  rich_or_poor: {
    description: 'Compare the following 360s and guess which one is better off.',
    which_home_is_wealthier: 'Which home is wealthier?'
  }
}
