import React from 'react'
import PropTypes from 'prop-types'

const getFeatured = house => {
  const featured = house.assets.find(asset => asset.categories.indexOf('featured') !== -1)
  return featured ? featured.url : false
}

const IntroBackground = props => {
  return (
    <div className="intro-background">
      {props.queue
        .filter((home, index) => index <= props.loadedIndex)
        .map((item, index) => {
          const featured = getFeatured(item)

          return (
            <div
              key={index}
              className={`intro-background-img ${index === props.current ? 'is-showing' : ''}`}
              style={{ backgroundImage: featured ? `url(/assets/houses/${featured})` : null }}
            />
          )
        })}
    </div>
  )
}

IntroBackground.propTypes = {
  current: PropTypes.number.isRequired,
  queue: PropTypes.array.isRequired,
  loadedIndex: PropTypes.number.isRequired
}

export default IntroBackground
