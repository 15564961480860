import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

import { addLocaleData, IntlProvider } from 'react-intl'

import 'normalize.css'
import 'core/sass/app.sass'

import en from 'react-intl/locale-data/en'

import { flattenMessages } from 'i18n/helpers/i18n-helpers'
import englishMessages from 'i18n/languages/en'

import App from 'core/components/App'

function start(err) {
  if (err) {
    console.error(err)
  } else {
    addLocaleData([...en])
    const language = 'en'
    const languageData = englishMessages
    ReactDOM.render(
      <IntlProvider locale={language} messages={flattenMessages(languageData)}>
        <App />
      </IntlProvider>,
      document.getElementById('app')
    )
  }
}

if (!window.Intl) {
  console.error('No window.Intl')
} else {
  start()
}
