import React from 'react'

import IntlContext from 'core/contexts/Intl'
import logo from '../../assets/video/gates-logo.png'

const About = props => {
  return (
    <IntlContext>
      {intl => {
        return (
          <article className="about">
            <div className="about-inner">
              {intl.locale === 'en' && (
                <div>
                  <h1>About One Hundred Homes </h1>
                  <p>
                    With increasing interest in economic inequality around the world and, especially, 
                    in this long period of economic growth in India, there is always a risk of losing 
                    sight of what ordinary peoples’ lives are like that underpin the abstractions of 
                    “inequality” and “poverty”. Since actual policy is often guided by such abstractions, 
                    the purpose of this project is to tether the very concretely-defined statistics to the
                    way the lived lives underlying them actually look. The hope is that people will come 
                    to approach public discussions of income inequality and poverty with a grounded sense 
                    of the reality behind the numbers. And perhaps more skepticism.
                  </p>
                  <p>
                    One Hundred Homes illustrates with pictures, videos, 360 images, and family descriptions
                    what people inside the statistics of income or consumption interviewed for standard 
                    government surveys look like in real life. It organizes the one hundred visual essays
                    according to the results of a household consumption survey used throughout India, 
                    and the world for that matter, that are used by researchers, statisticians and 
                    policy makers to bring out the richness of life under the numbers.
					One Hundred Homes is the result of a collaboration between journalists, economists, 
					sociologists, photograpers, videographers, survey researchers and others as follows:
                  </p>

                  <ul className="about-credits">
                  	<li className="about-credits-item"><span className="role">Videographer: </span>Milann Abraham</li>
                   	<li className="about-credits-item"><span className="role">Photographer: </span>Andrew Ash</li>
                    <li className="about-credits-item"><span className="role">Narrator: </span>Avantee Aneeta</li>
                    <li className="about-credits-item"><span className="role">Field Translator: </span>Pooja Bohra</li>
                    <li className="about-credits-item"><span className="role">Videographer: </span>Alice Cafrae</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Ravella Beena Chowdary</li>
                    <li className="about-credits-item"><span className="role">Initial Development: </span>Carolyn Edelstein</li>
                    <li className="about-credits-item"><span className="role">Website Producer: </span>Heather Grieve</li>
                    <li className="about-credits-item"><span className="role2">Produced By: </span>Jeffrey Hammer</li>
                    <li className="about-credits-item"><span className="role">Statistician: </span>Brijlal Joshi</li>
                    <li className="about-credits-item"><span className="role">Survey Researcher: </span>Jitendra Kumar</li> 
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Amrita Lamba</li> 
                    <li className="about-credits-item"><span className="role">Editor: </span>Mannika Mishra</li>   
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Nithya Nagarathinam</li>                   
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Sanjana Patro</li>
                    <li className="about-credits-item"><span className="role">Field Translator: </span>Dinesh Pandey</li>
                    <li className="about-credits-item"><span className="role">Survey Researcher: </span>Mangla Prasad</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Jith J R </li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Dibakar Das Roy</li>
                    <li className="about-credits-item"><span className="role">Field Translator: </span>Chameli Roy</li>
                    <li className="about-credits-item"><span className="role">Developer: </span>Jen Saxena</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Katyayni Seth</li>
                    <li className="about-credits-item"><span className="role">Journalist: </span>Shreya Shah</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Sarayu Srinivasan</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Lovey Pant</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Nikhil Srivastav</li>
                    <li className="about-credits-item"><span className="role">Project Development: </span>Rachel Steinberg</li>
                    <li className="about-credits-item"><span className="role">Lead Developer: </span>Dan Sundy</li>
                    <li className="about-credits-item"><span className="role">Journalist: </span>Divya Trivedi</li>                   
                    <li className="about-credits-item"><span className="role">Field Translator: </span>Jyoti Tripathi</li>
                    <li className="about-credits-item"><span className="role">Field Translator: </span>Manisha Vyas</li>
                    <li className="about-credits-item"><span className="role">Survey Researcher: </span>Premlata Verma</li> 
                    <li className="about-credits-item"><span className="role">Animator: </span>Justin Wotherspoon</li>
                    <li className="about-credits-item"><span className="role2">Directed By: </span>Evan Wexler</li>
                    <li className="about-credits-item"><span className="role">Supporting Logistics: </span>Zara Wexler</li>
                    <li className="about-credits-item"><span className="role">Editor: </span>Adam Wiseman</li>
                    <li className="about-credits-item"><span className="role">Designer: </span>Alex Wittholtz</li>
                    <li className="about-credits-item"><span className="role">Sociologist: </span>Chaitra Yadavar</li>
                    <li className="about-credits-item"><span className="role">Field Producer/Photographer: </span>Rajan Zaveri</li>
                  </ul>
                </div>
                
				)}
				
				<div> 
				<br/>
                <h2>Notes on Methodology</h2> 
                <p> 
                Large scale surveys tell us precisely what the overall distribution of income (or consumption) is. 
                The project matches individual homes to their rightful “slots” in the income distribution to show 
                what people at different levels of income look like. We did not do any research of our own to 
                determine what the distribution of consumption is in India. We took this information 
                from standard sources.
                </p> 
                <p> 
                The survey that was used to define that distribution was the India Human Development Survey (IHDS)
                of 2012 administered by the National Council of Applied Economic Research in Delhi. 
                The questionnaire used in that survey is exactly the same as the one used by the National Sample 
                Survey (NSS) done by the government every year or two. The IHDS survey consists of over 42000 
                households nationwide. This is about the same number as the NSS “thin” round (every five years
                they do a larger “thick” round of about 120,000).  The surveys give virtually identical results. 
                The One Hundred Homes project took this distribution as given – one hundred is a tiny number 
                in comparison. There was no attempt to independently estimate the distribution or to do any
                statistical inference from the data at all. 
                </p> 
                <p> 
                Since one hundred is such a small number, we formed a histogram using one hundred boxes to fit 
                inside the “true” distribution from the larger surveys. Each of these boxes told us how many 
                people we needed to find at each level of income. The intervals – the width of the histogram – 
                were chosen to show the overall shape reasonably accurately.
                </p> 
                <p> 
                How did we find the homes? Being too difficult to find one hundred individual households across 
                the country, we decided to use twenty places and choose five homes from each. The twenty places 
                were roughly in proportion to the Indian population. There are six urban and fourteen rural 
                locations. One billion two hundred thousand divided by twenty is sixty million. Based on the 
                census, anyplace that had anything like sixty million people was assigned one location (rural
                Maharashtra and rural West Bengal were the closest matches). With some leeway (all of the rural
                south warranted three locations among four states; we slightly shortchanged some places like 
                rural Uttar Pradesh and Bihar to accommodate some smaller but important areas like Delhi, 
                Mumbai, the Northeast (always overlooked) and Himachal Pradesh (this is a VISUAL survey 
                so the artists among us insisted) even though they do not each have 60 million residents). 
                </p> 
                
                </div> 
                
                
                <div> 
                <br/> 
                <p> 
                <h3>This project was made possible by funding from</h3>
                <img
                      src={logo}
                      className="gates-png"
                      alt={intl.formatMessage({ id: 'global.gates-logo' })}
                />
                </p> 
                </div> 
                
            </div>
          </article>
        )
      }}
    </IntlContext>
  )
}

export default About
