import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import AppState from 'core/contexts/AppState'

import { updateMeta } from 'core/helpers/general-helpers'
import { getHouseByID, getPreviousHouse, getNextHouse } from 'core/helpers/house-helpers'
import { getCategoryByID, getHouseCategories } from 'core/helpers/category-helpers'
import { getAssetsByCategory, getAssetIndex } from 'core/helpers/asset-helpers'

import InfoWrapper from 'components/InfoWrapper'
import Info from 'components/Info'
import AssetSlider from 'components/AssetSlider'
import CategorySelect from 'components/CategorySelect'
import LayoutToggle from 'components/LayoutToggle'
import HouseDetails from 'components/HouseDetails'

class Category extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      house: null,
      category: null,
      assets: null,
      current: null
    }

    this.onSelect = this._onSelect.bind(this)
  }

  componentDidMount() {
    const house = getHouseByID(this.props.houseID, this.context.state.houses)
    const category = getCategoryByID(this.props.categoryID, this.context.state.categories)

    const assets = getAssetsByCategory(house.assets, category.id)
    const params = queryString.parse(this.props.location.search)

    updateMeta({
      title: `Category: ${category.name}`,
      description: 'This is an individual category.'
    })

    this.context.setState({ house, category })
    this.setState({
      house,
      category,
      assets,
      current:
        params.position === 'last' ? assets.length - 1 : getAssetIndex(assets, params.id) || 0,
      previous: getPreviousHouse(this.props.houseID, this.context.state.houses),
      next: getNextHouse(this.props.houseID, this.context.state.houses)
    })
  }

  _onSelect(val) {
    this.props.history.push(`/the-homes/house/${this.state.house.House_ID}/category/${val}`)
  }

  render() {
    const houseCategories = getHouseCategories(
      this.context.state.categories,
      this.state.house,
      (this.state.category || {}).id
    )
    return (
      <article className="category">
        {this.state.house && this.state.category && (
          <Fragment>
            {!this.state.assets.length && (
              <div className="category-no-results">
                <p className="category-no-results-text">
                  <FormattedMessage
                    id="category.no_assets"
                    values={{ category: this.state.category.name }}
                    defaultMessage="No assets in this category"
                  />
                </p>
              </div>
            )}
            {typeof this.state.current === 'number' && (
              <AssetSlider
                house={this.state.house}
                assets={this.state.assets || []}
                initial={this.state.current}
                categories={houseCategories}
                currentCategory={this.state.category}
              />
            )}

            <div className="u-scrim" />

            <InfoWrapper>
              <Info
                house={this.state.house}
                navigation={{
                  previous: `/the-homes/house/${this.state.previous.House_ID}/category/${this.state.category.id}`,
                  next: `/the-homes/house/${this.state.next.House_ID}/category/${this.state.category.id}`
                }}
              />
            </InfoWrapper>

            <InfoWrapper className={'mod-bottom'}>
              <HouseDetails house={this.state.house} />
              <CategorySelect
                categories={houseCategories}
                selected={this.state.category.id}
                onSelect={this.onSelect}
              />
            </InfoWrapper>

            <LayoutToggle
              categories={this.context.state.categories}
              house={this.state.house}
              active="slideshow"
            />
          </Fragment>
        )}
      </article>
    )
  }
}

Category.propTypes = {
  houseID: PropTypes.string.isRequired,
  categoryID: PropTypes.string.isRequired
}

Category.contextType = AppState

export default Category
