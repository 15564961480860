import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { AppStateConsumer } from 'core/contexts/AppState'
import Header from 'components/Header'
import Main from 'core/components/Main'

import Overview from 'views/Overview'
import Lessons from 'views/Lessons'
import TheHomes from 'views/TheHomes'
import About from 'views/About'

import 'core/sass/components/animation.sass'

class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewIndex: 0,
      direction: 'down'
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const paths = ['/', '/lessons', '/the-homes', '/about']
    const pathArray = props.location.pathname.split('/')
    const viewKey = `/${pathArray[1]}`

    const viewIndex = paths.findIndex(path => path === viewKey)

    if (viewIndex === prevState.viewIndex) return null

    const direction = viewIndex < prevState.viewIndex ? 'down' : 'up'
    return { viewIndex, direction }
  }

  componentDidMount() {
    // Initialize analytics and log page changes
    ReactGA.initialize('UA-158852513-1', {
      testMode: global.ENVIRONMENT === 'test'
    })
    ReactGA.pageview(location.pathname + location.search)
    this.props.history.listen(location => {
      ReactGA.pageview(location.pathname + location.search)
    })
  }

  render() {
    return (
      <Route
        render={({ location }) => {
          const animClasses = `slide-${this.state.direction}`
          return (
            <AppStateConsumer>
              {context => {
                if (context.state.status === 'loading') {
                  return (
                    <Main>
                      <div className="loading">
                        <FormattedMessage id="global.loading" defaultMessage="global.loading" />
                      </div>
                    </Main>
                  )
                } else {
                  return (
                    <Main>
                      <Header location={location} />
                      <TransitionGroup
                        component={null}
                        childFactory={childFactoryCreator(animClasses)}
                      >
                        <CSSTransition
                          key={this.state.viewIndex}
                          classNames={animClasses}
                          timeout={700}
                        >
                          <Switch location={location}>
                            <Route exact path="/" render={props => <Overview />} />
                            <Route
                              path="/lessons/:index"
                              render={props => <Lessons {...props} location={location} />}
                            />
                            <Route
                              path="/the-homes"
                              render={props => (
                                <TheHomes homes={context.state.homes} location={location} />
                              )}
                            />
                            <Route path="/about" render={props => <About />} />
                            <Redirect from="/lessons" to="/lessons/1" />
                          </Switch>
                        </CSSTransition>
                      </TransitionGroup>
                    </Main>
                  )
                }
              }}
            </AppStateConsumer>
          )
        }}
      />
    )
  }
}

const childFactoryCreator = classNames => child =>
  React.cloneElement(child, {
    classNames
  })

export default Routes
