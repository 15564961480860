import React from 'react'
import PropTypes from 'prop-types'
import Routes from 'core/components/Routes'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { AppStateProvider } from 'core/contexts/AppState'
import { IntlProvider } from 'core/contexts/Intl'

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <IntlProvider>
        <AppStateProvider>
          <Router basename="/">
            <Route component={Routes} />
          </Router>
        </AppStateProvider>
      </IntlProvider>
    )
  }
}

App.contextTypes = {
  intl: PropTypes.object
}

export default App
