export const currency = (value, options = {}) => {
  const opts = {
    currency: 'USD',
    ...options
  }

  const country = opts.currency === 'INR' ? 'en-IN' : 'en-US'

  const formatter = new Intl.NumberFormat(country, {
    style: 'currency',
    currency: opts.currency,
    minimumFractionDigits: 2,
    currencyDisplay: 'symbol'
  })

  return formatter.format(value)
}

export const dollarsToRupees = value => {
  return value * 18.149
}
