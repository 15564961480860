import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import IntlContext from 'core/contexts/Intl'
import { currency, dollarsToRupees } from 'core/helpers/language-helpers'

const Info = props => {
  const dpd = props.house.dollar_per_day_per_capita
  return (
    <IntlContext>
      {intl => {
        return (
          <div className="info">
            {(props.navigation || {}).previous && (
              <div className="info-prev">
                <Link
                  to={props.navigation.previous}
                  title={intl.formatMessage({ id: 'global.less_money' })}
                  className="info-nav-btn"
                >
                  <div className="info-minus-icon">
                    <span className="u-screen-reader-text">
                      <FormattedMessage id="global.less_money" />
                    </span>
                  </div>
                </Link>
              </div>
            )}

            <div className="info-ppp">
              <div className="info-ppp-value">
                {currency(dpd)} / {currency(dollarsToRupees(dpd), { currency: 'INR' })}
              </div>
              <div className="info-ppp-label">
                <FormattedMessage id="global.per_day" />
              </div>
            </div>

            {(props.navigation || {}).next && (
              <div className="info-next">
                <Link
                  to={props.navigation.next}
                  className="info-nav-btn"
                  title={intl.formatMessage({ id: 'global.more_money' })}
                >
                  <div className="info-plus-icon">
                    <span className="u-screen-reader-text">
                      <FormattedMessage id="global.more_money" />
                    </span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        )
      }}
    </IntlContext>
  )
}

Info.propTypes = {
  house: PropTypes.object.isRequired,
  navigation: PropTypes.shape({
    next: PropTypes.string,
    previous: PropTypes.string
  })
}

export default Info
