import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

const AssetSliderImage = props => {
  if (!props.preloaded) return null

  return (
    <div className="asset-slider-image">
      <img className="asset-slider-image-img" src={`/assets/houses/${props.media.url}`} alt="" />
    </div>
  )
}

AssetSliderImage.propTypes = {
  media: PropTypes.object.isRequired,
  preloaded: PropTypes.bool.isRequired
}

export default AssetSliderImage
