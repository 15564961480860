import React from 'react'

import PropTypes from 'prop-types'

const Paragraphed = props => {
  const text = (props.children || '')
    .split('\n')
    .map(txt => txt.trim())
    .filter(txt => txt.length > 0)
  return (
    <React.Fragment>
      {text.map((paragraph, i) => (
        <p key={i}>{paragraph}</p>
      ))}
    </React.Fragment>
  )
}

Paragraphed.propTypes = {
  children: PropTypes.string
}

export default Paragraphed
